define('Nwdthemes_Powerbanners/js/powerbanner',[
    'jquery',
    'nwdthemes/jquery.easing',
    'nwdthemes/owl.carousel'
], function($){

    if (!("ontouchstart" in document.documentElement) && document.documentElement.className.indexOf('no-touch') < 0) {
        document.documentElement.className += " no-touch";
    }

    return function (config, element) {

        var nwdLog = function() {
            if ( 0 && window.console ) console.log(arguments);
        }

        var pixelRatio = !!window.devicePixelRatio ? window.devicePixelRatio : 1;
        if (pixelRatio > 1) {
            $('img[data-retina]', element).each(function(){
                $(this).attr('src',$(this).attr('data-retina'));
            });
        }

        nwdLog('initSlider', $(element).attr('id'));
        $(element).css({
            display: 'block',
            height: '0px'
        });

        var sliderID = $(element).attr('id'),
            nwdPB = '#'+sliderID,
            titleWrapper = $(nwdPB+' .powerbanner-wrapper .title-wrapper'),
            titleWrapperPadding = parseInt(titleWrapper.css('padding-left')),
            titleSpanPadding = parseInt(titleWrapper.find('span').css('padding-left')),
            naturalWidth = $(nwdPB).data('width'),
            naturalHeight = $(nwdPB).data('height');

        var resetTitleParameters = function(){
            nwdLog('resetTitleParameters');
            $('span', titleWrapper).css({
                width: '',
                paddingLeft: '',
                paddingRight: ''
            }).data('width', '');
            $('span', titleWrapper).each(function () {
                $(this).data('width', $(this).width() + titleSpanPadding*2 + 1);
            });
            $('span', titleWrapper).css({
                display: 'block',
                width: '0px',
                paddingLeft: '0px',
                paddingRight: '0px'
            });
        };

        var reCalculateParameters = function(){
            nwdLog('reCalculateParameters');
            ratio =  $(nwdPB + ' img').width() / naturalWidth;
            titleWrapper.css('padding', titleWrapperPadding * ratio + 'px' );
            $('span', titleWrapper).each(function () {
                $(this).css('font-size', parseInt($(this).data('size')) * ratio + 'px');
            });
            $(nwdPB).animate(
                { height : naturalHeight * ratio +'px' },
                1000,
                'easeOutExpo'
            );
        };

        var showTitle = function(){
            nwdLog('showTitle');
            var slides = owl.data('owlCarousel').owl.owlItems;
            for (var i=0; i < slides.length; i++) {
                if ( $.inArray( i, owl.data('owlCarousel').owl.visibleItems ) > -1 ) {
                    $('.title-wrapper span', slides[i]).each(function () {
                        $(this)
                            .css({
                                width: $(this).data('width'),
                                paddingLeft: '',
                                paddingRight: ''
                            });
                    });
                } else {
                    $('.title-wrapper span', slides[i]).css({
                        width: '0px',
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    });
                }
            }
        };

        var owl = $(nwdPB),
            options = {
                slideSpeed: 400,
                paginationSpeed: 400,
                rewindSpeed: 400,
                stopOnHover: true,
                navigation: true,
                navigationText: ['<i class="fa fa-chevron-left">','<i class="fa fa-chevron-right">'],
                pagination: true,
                paginationNumbers: true,
                itemsScaleUp: false,
                rewindNav: false,
                responsiveBaseWidth: $(nwdPB).parent(),
                responsiveRefreshRate: 256,
                beforeInit: function() {
                    nwdLog('beforeInit');

                    $('span', titleWrapper).each(function () {
                        $(this).data('size', $(this).css('font-size'));
                    });

                    resetTitleParameters();

                    $('.no-touch .powerbanner-wrapper').hover(
                        function () {
                            $(this).find('.title-wrapper').addClass('hover');
                            $(this).find('.title-wrapper span').width($(this).width());
                        },
                        function () {
                            $(this).find('.title-wrapper').removeClass('hover');
                            $(this).find('.title-wrapper span').each(function () {
                                $(this).css('width', $(this).data('width'));
                            });

                        }
                    );
                },
                afterInit: function() {
                    nwdLog('afterInit');
                    var that = this,
                        w = $(window).width();
                    reCalculateParameters();
                    setTimeout( function(){
                        if ( w != $(window).width() ) {
                            that.updateVars();
                        } else {
                            resetTitleParameters();
                            setTimeout( function(){ showTitle(); }, options.slideSpeed);
                        }
                    }, 300);
                },
                beforeUpdate: function() {
                    nwdLog('beforeUpdate');
                    $('span', titleWrapper)
                        .css({
                            display: 'none',
                            width: '0px',
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        });
                },
                afterUpdate: function() {
                    nwdLog('afterUpdate');
                    reCalculateParameters();
                    setTimeout(
                        function(){
                            resetTitleParameters();
                            setTimeout( function(){ showTitle(); }, options.slideSpeed);
                        },
                        200
                    );
                },
                afterMove: function() {
                    nwdLog('afterMove');
                    setTimeout( function(){ showTitle(); }, options.slideSpeed);
                },
                afterAction: function(){
                    nwdLog('afterAction');
                    if ( !this.options.navigation ) return;

                    if ( this.itemsAmount > this.visibleItems.length ) {
                        $(this.owlControls).show();
                        $('.owl-buttons div', this.owlControls).removeClass('disabled');
                        if ( this.currentItem == 0 ) $(this.buttonPrev).addClass('disabled');
                        if ( this.currentItem == this.maximumItem ) $(this.buttonNext).addClass('disabled');
                    } else {
                        $(this.owlControls).hide();
                    }
                }
            };

        $.extend(options, config);
        owl.owlCarousel(options);
    }
});
